import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuxiliarService } from './services/auxiliar.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  public loading: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private translate: TranslateService,
    public auxiliarService: AuxiliarService,
    private messageService: MessageService,
    private primeNGConfig: PrimeNGConfig
  ) {
    // observable to trigger toast
    this.auxiliarService.toastObservable.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (_.get(value, 'message')) {
        this.messageService.add({
          severity: _.get(value, 'type', 'info'),
          summary: _.get(value, 'message'),
          key: 'toast',
          life: 5000
        });
      }
    });
    // prime translations
    this.translate.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }
}
