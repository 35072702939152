import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER: string = 'Authorization';

  constructor(private cookieService: CookieService) {}

  private addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.cookieService.get('auth');
    // If we do not have a token, yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!token) {
      return req;
    }
    return req.clone({
      headers: req.headers.set(this.AUTH_HEADER, 'Bearer ' + token)
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.addAuthenticationToken(request);
    return next.handle(request);
  }
}
