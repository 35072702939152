<router-outlet></router-outlet>

<!--app toast-->
<app-toast></app-toast>

<!-- confirm dialog -->
<p-confirmDialog #cd key="dialog" class="confirm-dialog">
  <ng-template pTemplate="headless" let-message>
    <div class="confirmation-body">
      <p [innerHTML]="message.message" class="bold"></p>
      <div class="buttons-confirmation">
        <button type="button" pButton label="{{ 'utils.no' | translate }}" class="p-button-text p-confirm-dialog-reject"
          (click)="cd.reject()">
        </button>
        <button type="button" pButton label="{{ 'utils.yes' | translate }}" class="p-confirm-dialog-accept"
          (click)="cd.accept()">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<!-- popup -->
<p-confirmPopup #cp key="popup" class="confirm-popup">
  <ng-template pTemplate="headless" let-message>
    <div class="confirmation-body">
      <p [innerHTML]="message.message" class="bold"></p>
      <div class="buttons-confirmation">
        <button type="button" pButton label="{{ 'utils.no' | translate }}" class="p-button-text p-confirm-popup-reject"
          (click)="cp.reject()">
        </button>
        <button type="button" pButton label="{{ 'utils.yes' | translate }}" class="p-confirm-popup-accept"
          (click)="cp.accept()">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmPopup>
