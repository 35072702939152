import {
  HttpRequest,
  HttpEvent,
  HttpHandlerFn,
  HttpEventType
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import * as _ from 'lodash';

export function ErrorHandlerInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(req).pipe(tap(res => {
    if (res.type === HttpEventType.Response) {
      const krakendCompleted = _.get(res, 'headers')?.get('x-krakend-completed');
      if (krakendCompleted && krakendCompleted === "false") {
        let err = {};
        for (let n = 0; n < 5; n++) {
          if (_.get(res, 'body.error_' + n)) {
            _.set(err, 'message', JSON.parse(_.get(res, 'body.error_' + n + '.http_body')));
            throw err;
          }
        }
        throw res;
      }
    }
  }));
}
