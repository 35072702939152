import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';
import * as _ from 'lodash';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastComponent } from './components/toast/toast.component';
import { ErrorHandlerInterceptor } from './helpers/error-handler.interceptor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './helpers/auth.guard';
import { ConfirmPopupModule } from 'primeng/confirmpopup';


// =====================================
// ======= translation functions =======
// =====================================
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector): object {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const navLang = navigator.language ? _.first(_.split(navigator.language, '-')) : null;
      const langToSet = navLang ? navLang : 'es';
      translate.setDefaultLang('es');
      translate.use(langToSet || 'es').subscribe({
        next: () => {
        },
        error: () => {
          console.error(`Problem with '${langToSet}' language initialization.'`);
        },
        complete: resolve(null)
      });
    });
  });
}

// =====================================

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ConfirmDialogModule,
    ConfirmPopupModule,
  ],
  providers: [
    AuthGuard,
    MessageService,
    JwtHelperService,
    ConfirmationService,
    CookieService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true },
    provideHttpClient(
      withInterceptors([ErrorHandlerInterceptor]),
    )
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
