import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { LANGUAGES } from 'src/assets/configs/languages';

@Injectable({
  providedIn: 'root'
})
export class AuxiliarService {
  private languages = LANGUAGES;
  private toastSubject = new BehaviorSubject({});
  public toastObservable = this.toastSubject.asObservable();

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router
  ) { }

  // get lang description text to fields labels
  public getLangText(langId: string): string {
    return `(${_.get(_.find(this.languages, { id: langId }), 'text')})`;
  }

  // detect nav lang or set 'es' by default
  public getLang(): string {
    return navigator.language ? _.first(_.split(navigator.language, '-'))! : 'es';
  }

  public confirm(message: string, group: string, target?: EventTarget): Promise<boolean> {
    return new Promise(resolve => {
      this.confirmationService.confirm({
        message: message,
        acceptIcon: "none",
        rejectIcon: "none",
        target: target,
        key: group,
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  // function to trigger toast message in app component
  public toast(message: string, type?: string): void {
    this.toastSubject.next({ message, type });
  }

  // function to get current route section
  public getSyllabusSection(): string | undefined {
    return _.last(_.split(this.router.url, '/'));
  }
}
